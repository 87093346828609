@font-face {
	font-family: "LexendRegular";
	src: local("LexendRegular"),
		url("./fonts/Lexend-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "LexendMedium";
	src: local("LexendMedium"),
		url("./fonts/Lexend-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "LexendBold";
	src: local("LexendBold"), url("./fonts/Lexend-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "LexendLight";
	src: local("LexendLight"), url("./fonts/Lexend-Light.ttf") format("truetype");
}

@font-face {
	font-family: "InterRegular";
	src: local("InterRegular"),
		url("./fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "InterMedium";
	src: local("InterMedium"), url("./fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "InterBold";
	src: local("InterBold"), url("./fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "InterLight";
	src: local("InterLight"), url("./fonts/Inter-Light.ttf") format("truetype");
}

* {
	position: relative;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: InterRegular;
	font-size: 1rem;
	color: #050911;
	background-color: #f7f8fc;
}

a {
	text-decoration: none;
}

a:not(.decorated) {
	color: #050911;
}

textarea {
	resize: none;
	min-height: 100px !important;
}

.test {
	border: 1px solid red;
}

.flex-column {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-center {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-left {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: left;
	justify-content: left;
}

.flex-row-right {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
}

.full-height {
	height: 100% !important;
}

.full {
	width: 100% !important;
}

.half {
	width: 50% !important;
}

.fit {
	width: fit-content !important;
}

.ellipsis {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.group-item-details {
	width: calc(100% - 130px);
	/* border: 1px solid red; */
}

.hidden {
	display: none;
}

.block {
	display: block !important;
}

.border {
	border: 1px solid rgba(229, 231, 235, 0.8) !important;
}

.border-top {
	border-top: 1px solid rgba(229, 231, 235, 0.8) !important;
}

.border-left {
	border-left: 1px solid rgba(229, 231, 235, 0.8) !important;
}

.border-bottom {
	border-bottom: 1px solid rgba(229, 231, 235, 0.8) !important;
}

.border-right {
	border-right: 1px solid rgba(229, 231, 235, 0.8) !important;
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.full-page {
	min-height: 100vh !important;
}

.text-regular-lexend {
	font-family: LexendRegular;
}

.text-medium-lexend {
	font-family: LexendMedium;
}

.text-bold-lexend {
	font-family: LexendBold;
}

.text-light-lexend {
	font-family: LexendLight;
}

.text-regular-inter {
	font-family: InterRegular;
	/* font-weight: normal; */
}

.text-medium-inter {
	font-family: InterMedium;
	/* font-weight: 600; */
}

.text-bold-inter {
	font-family: InterBold !important;
	/* font-weight: 700; */
}

.text-light-inter {
	font-family: InterLight;
}

.text-white {
	color: #ffffff !important;
}

.text-light {
	color: #828488 !important;
}

.text-blue {
	color: #3c57cc !important;
}

.text-blue-dark {
	color: #0d163f !important;
}

.text-default {
	color: #050911 !important;
}

.text-orange {
	color: #ff7900 !important;
}

.text-orange-light {
	color: #ff9a6b !important;
}

.text-orange-dark {
	color: #d44513 !important;
}

.text-red {
	color: #ff3819 !important;
}

.title-lg {
	font-size: 2.5rem;
}

.title-xl {
	font-size: 3rem;
}

.title-md {
	font-size: 2rem;
}

.text-xl {
	font-size: 1.5rem !important;
}

.text-lg {
	font-size: 1.125rem !important;
}

.text-md {
	font-size: 1rem !important;
}

.text-sm {
	font-size: 0.875rem !important;
}

.label-md {
	font-size: 0.75rem !important;
}

.label-sm {
	font-size: 0.563rem !important;
}

.opaque {
	opacity: 1 !important;
}

.opacity-1 {
	opacity: 0.1 !important;
}

.opacity-3 {
	opacity: 0.3 !important;
}

.opacity-5 {
	opacity: 0.5 !important;
}

.opacity-7 {
	opacity: 0.7 !important;
}

.upper {
	text-transform: uppercase;
}

.p-10 {
	padding: 10px;
}

.p-20 {
	padding: 20px;
}

.p-30 {
	padding: 30px;
}

.p-40 {
	padding: 50px;
}

.p-50 {
	padding: 50px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-50 {
	margin-bottom: 50px;
}

.btn-default {
	background-color: #3c57cc !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-default:hover {
	background-color: rgba(60, 87, 204, 0.9) !important;
}

.btn-default:active {
	background-color: #334fcb !important;
}

.btn-default-outline {
	background-color: transparent !important;
	border: 1px solid #3c57cc !important;
	color: #3c57cc !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-default-outline:hover {
	background-color: rgba(60, 87, 204, 0.9) !important;
	color: #fff !important;
}

.btn-default-outline:active {
	background-color: #334fcb !important;
	color: #fff !important;
}

.btn-gray {
	background-color: #f7f8fc !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-gray:hover {
	background-color: #eeeff1 !important;
}

.btn-gray:active {
	background-color: #dfe0e1 !important;
}

.btn-gray-dark {
	background-color: #e0e1e3 !important;
	color: #050911 !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-gray-dark:hover {
	background-color: #d3d3d5 !important;
}

.btn-gray-dark:active {
	background-color: rgb(195, 195, 196) !important;
}

.btn-danger {
	background-color: #ff3819 !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-danger:hover {
	background-color: rgba(255, 56, 25, 0.9) !important;
}

.btn-danger:active {
	background-color: #e02f14 !important;
}

.bg-cover {
	background-size: cover !important;
	background-position: center !important;
}

.bg-default {
	background-color: #0d163f !important;
}

.bg-white {
	background-color: #ffffff !important;
}

.bg-gray {
	background-color: #f7f8fc;
}

.bg-black {
	background-color: #050911;
}

.bg-blue {
	background-color: #3c57cc !important;
}

.bg-orange-light {
	background: #fad9bb;
}

.rounded {
	border-radius: 8px !important;
}

.rounded-4 {
	border-radius: 4px !important;
}

.main-nav {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 300px;
	z-index: 4;
}

.content {
	padding-top: 90px;
	padding-bottom: 100px;
}

.dashboard-content {
	padding-top: 70px;
}

.login-header {
	height: 100px;
	z-index: 4;
}

.login-separator {
	background-color: #eee;
	height: 1px;
	width: 100%;
}

.login-form-container {
	height: calc(100vh - 100px);
}

.form-block {
	margin-bottom: 30px;
}

.login-input {
	height: 55px;
}

.btn-rounded {
	border-radius: 100px;
}

.btn-lg {
	height: 55px !important;
}

.btn-md {
	height: 48px !important;
	padding-left: 20px;
	padding-right: 20px;
}

.btn-md i {
	font-size: 1.2rem;
	margin-right: 10px;
}

.btn-md-loader {
	height: 28px;
	width: 28px;
	font-size: 0.8rem;
}

.btn-sm {
	height: 38px !important;
	padding-left: 20px;
	padding-right: 20px;
}

.btn-sm i {
	font-size: 1.2rem;
	margin-right: 10px;
}

.btn-xs {
	height: 30px !important;
	padding-left: 12px;
	padding-right: 12px;
}

.btn-xs i {
	font-size: 1.2rem;
	margin-right: 7px;
}

input::placeholder {
	color: #050911 !important;
	opacity: 0.3 !important;
}

input.error {
	border-color: #ff3819 !important;
}

.rotating {
	animation: rotating 1s linear infinite !important;
}

.nav-btn {
	padding: 15px 20px !important;
}

.nav-btn i {
	font-size: 1.2rem;
	margin-right: 5px;
}

.notif-icon {
	font-size: 1.875rem;
	margin: 0 30px;
}

.main-nav-header {
	height: 60px;
	padding: 14px 20px;
}

.nav-logo {
	width: 170px;
	margin-right: 30px;
}

.beta {
	position: absolute;
	bottom: -15px;
	right: 30px;
}

.main-nav-item {
	margin-right: 30px;
}

.main-nav-item:not(.active) > span,
.main-nav-item:not(.active) > i {
	opacity: 0.5;
}

.main-nav-item.active {
	opacity: 1;
}

.main-nav-icon {
	margin-right: 8px;
	font-size: 1.3rem;
}

.main-nav-item-count {
	position: absolute;
	right: 20px;
}

.main-nav-separator {
	margin: 10px 0;
	background: rgba(255, 255, 255, 0.3);
	height: 1px;
}

.main-nav-dropdown-icon {
	position: absolute;
	left: -17px;
	font-size: 15px !important;
}

.main-nav-item-count.active {
	padding: 0 8px;
	border-radius: 6px;
	background-color: #ff3819;
	right: 12px;
	font-size: 0.65rem;
	opacity: 1 !important;
}

.page-header {
	position: fixed;
	top: 0;
	left: 0;
	height: 70px;
	z-index: 8;
	box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
}

.page-loader:not(.full-page) {
	height: calc(100vh - 270px);
}

.page-loader.groups-page-loader {
	height: calc(100vh - 400px);
}

.header-search-container {
	height: 38px;
	width: 300px;
	padding: 0 15px;
}

.search-input {
	margin-left: 10px;
	padding: 0;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	background-color: transparent !important;
}

.header-separator {
	background-color: #050911;
	height: 20px;
	width: 2px;
	margin: 0;
}

.header-user-img {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	margin-left: 10px;
	background-size: cover !important;
	background-position: center !important;
}

.header-user-dropdown {
	margin-left: 15px;
}

.header-user-icon {
	font-size: 1.2rem;
}

.country-icon {
	height: 15px;
	width: 15px;
	border-radius: 50%;
	margin-right: 5px;
	background-size: cover !important;
	background-position: center !important;
}

.main-nav-more-arrow {
	margin-right: 5px;
}

.page-content-header {
	margin-bottom: 10px;
	height: 38px;
}

.page-content-header-action > span {
	margin-right: 5px;
}

.dashboard-item {
	padding: 20px 20px 17px 20px;
	margin-bottom: 15px;
}

.dashboard-item.unread .dashboard-item-sender {
	font-family: InterMedium;
	font-weight: 600;
}

.dashboard-item.unread::after {
	content: "";
	position: absolute;
	top: 15px;
	left: 15px;
	background-color: #ff3819;
	height: 7px;
	width: 7px;
	border-radius: 50%;
}

.dashboard-item-icon {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background-size: cover !important;
	background-position: center !important;
	margin-right: 10px;
}

.dashboard-item-details {
	width: calc(100% - 60px);
}

.dashboard-item-sender {
	margin-right: 5px;
}

.dashboard-item-content {
	margin-top: 10px;
}

.separator {
	width: 100%;
	background: rgba(5, 9, 17, 0.1);
	height: 1px;
}

.dashboard-item-info {
	margin-bottom: 30px;
}

.dashboard-item-meta {
	margin-top: 15px;
}

.dashboard-item-country {
	height: 12px;
	width: 12px;
	border-radius: 50%;
	margin: 0 5px;
}

.dashboard-item-control {
	position: absolute;
	top: 0;
	right: 0;
}

.mfa-item-control {
	position: absolute;
	bottom: 0;
	right: 0;
}

.inbox-msisdn-control,
.group-item-control,
.asset-control {
	position: absolute;
	right: 0;
	padding-right: 20px;
}

.btn-tertiary {
	background-color: #f7f8fc;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	color: #3c57cc !important;
	border-radius: 4px;
}

.btn-tertiary:hover {
	background-color: #e8ecf8;
}

.btn-tertiary:active {
	background-color: #334fcb !important;
	color: #fff !important;
}

.btn-tertiary:disabled {
	background-color: #334fcb;
	color: #fff !important;
}

.notification-item-control button,
.notification-item-control a {
	background-color: #f7f8fc;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	color: #3c57cc !important;
	border-radius: 3px;
	margin-left: 8px;
}

.control-btn {
	height: 30px;
	width: 30px;
	background-color: #f7f8fc;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	color: #3c57cc !important;
	border-radius: 3px;
	margin-left: 8px;
}

.control-btn:hover {
	background-color: #e8ecf8;
}

.control-btn:active {
	background-color: #334fcb !important;
	color: #fff !important;
}

.dashboard-item-control button,
.number-item-control button,
.numbers-recent-control button,
.mfa-item-control button,
.inbox-msisdn-control button,
.group-item-control button,
.asset-control button,
.settings-control button {
	height: 30px;
	width: 30px;
	background-color: #f7f8fc;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	color: #3c57cc !important;
	border-radius: 3px;
	margin-left: 8px;
}

.dashboard-item-control button:hover,
.notification-item-control button:hover,
.notification-item-control a:hover,
.number-item-control button:hover,
.numbers-recent-control button:hover,
.mfa-item-control button:hover,
.inbox-msisdn-control button:hover,
.group-item-control button:hover,
.asset-control button:hover,
.deleted-msisdn-control button:hover,
.settings-control button:hover {
	background-color: #e8ecf8;
}

.dashboard-item-control button:active,
.notification-item-control button:active,
.notification-item-control a:active,
.number-item-control button:active,
.numbers-recent-control button:active,
.mfa-item-control button:active,
.inbox-msisdn-control button:active,
.group-item-control button:active,
.asset-control button:active,
.deleted-msisdn-control button:active,
.deleted-msisdn-control button:disabled,
.settings-control button:active {
	background-color: #334fcb !important;
	color: #fff !important;
}

.numbers-country-picker {
	background-color: transparent !important;
}

.numbers-country-picker:not(:last-of-type) {
	margin-right: 15px;
}

.content-item {
	padding: 20px;
	margin-bottom: 15px;
	cursor: pointer;
}

.numbers-cta {
	padding: 20px;
	margin-bottom: 30px;
}

.numbers-cta-desc {
	margin-bottom: 15px;
}

.numbers-recent-container,
.mfa-recent-container {
	padding: 20px;
	margin-top: 15px;
}

.numbers-recent-item:not(:last-of-type),
.mfa-recent-item:not(:last-of-type) {
	border-bottom: 1px solid rgba(5, 9, 17, 0.1);
	padding-bottom: 20px;
}

.numbers-recent-item:not(:first-of-type),
.mfa-recent-item:not(:first-of-type) {
	padding-top: 20px;
}

.numbers-recent-control,
.mfa-recent-control {
	position: absolute;
	right: 0;
}

.mfa-username {
	margin-bottom: 5px;
}

.mfa-code {
	letter-spacing: 1.6px;
}

.inbox-msisdn-container > div {
	padding: 20px;
}

.msisdn-country {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	margin-right: 5px;
	border: 1px solid #eee;
}

.group-icon {
	width: 50px;
	height: 50px;
	border-radius: 8px;
	margin-right: 10px;
}

.round-loader {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	position: relative;
	overflow: hidden;
}

.circle {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50%;
	padding: 4px;
}

.circle-filler {
	height: 100%;
	width: 100%;
	border-radius: 50%;
}

.modal-btn.dismiss-btn {
	background-color: #fff !important;
	border: none !important;
	outline: none !important;
}

.modal-btn {
	min-width: 100px;
}

.mfa-modal-btn {
	padding-left: 30px;
}

.mfa-modal-btn:first-of-type {
	margin-bottom: 20px;
}

.dropdown-toggle.no-caret:after {
	content: none;
}

.form-input {
	height: 38px !important;
}

.form-block-sm {
	margin-bottom: 20px;
}

.empty-label {
	margin-top: 30px;
}

.init-btn {
	margin-top: 30px;
}

.group-member-icon {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	margin-right: 10px;
}

.group-member-btn {
	height: 30px !important;
	min-width: 75px;
}

.member-invite-label {
	padding: 4px 6px;
	color: #38c976;
	border: 1px solid #38c976;
}

.MuiTabs-indicator {
	background-color: #131f57 !important;
}

.settings-tab {
	text-transform: none !important;
	width: fit-content !important;
	padding: 0 !important;
	min-width: 0 !important;
	width: fit-content !important;
	margin-right: 30px !important;
	padding-top: 0 !important;
}

.settings-tab:not(.Mui-selected) {
	font-family: InterRegular;
	opacity: 0.5;
}

.settings-tab.Mui-selected {
	font-family: InterMedium;
	opacity: 1;
}

.MuiTabs-scroller {
	border-bottom: none !important;
}

.settings-tab-content {
	padding: 30px 0;
}

.settings-action-btn {
	width: 180px;
}

.settings-email {
	margin-right: 5px;
}

.verify-instructions {
	height: 38px;
	padding: 0 20px;
	margin-top: 15px;
}

.verify-email-container {
	position: absolute;
	right: 20px;
}

.modal-qr-container {
	padding-top: 30px;
	padding-bottom: 30px;
}

.modal-qr {
	height: 200px !important;
	width: 200px !important;
}

.verify-logo {
	height: 120px;
}

.verify-title {
	margin-bottom: 15px;
}

.forward-option {
	height: 50px;
}

.forward-option-value {
	width: 75%;
}

.lbl {
	/* position: absolute;
	right: 0; */
	/* margin-top: 5px; */
	margin-right: 15px;
	height: 16px;
	width: 37px;
	background: #c8c7c7;
	border-radius: 100px;
	cursor: pointer;
	transition: all 0.3s ease;
}

.lbl.asset-lbl {
	margin-top: 3px;
}

.lbl::after {
	position: absolute;
	left: -2px;
	top: -3px;
	width: 22px;
	height: 22px;
	border-radius: 100px;
	background: white;
	border: 1px solid #ddd;
	box-shadow: 0px 3px 3px rgba(#000, 0.05);
	content: "";
	transition: all 0.3s ease;
}

.lbl:active::after {
	transform: scale(1.15, 0.85);
}

.cbx:checked ~ label {
	background: #0d163f !important;
}

.cbx.asset-cbx:checked ~ label {
	background: #3048b0 !important;
}

.cbx:checked ~ label::after {
	left: 16px;
	/* background: #519efd;
	border: 1px solid #519efd; */
}

.cbx:disabled ~ label {
	background: #d5d5d5;
	border: 1px solid #d5d5d5;
	pointer-events: none;
}

.cbx:disabled ~ label::after {
	background: #bcbdbc;
	border: 1px solid #bcbdbc;
}

.page-loader-icon {
	height: 38px;
	width: 38px;
}

.payment-form-container {
	width: 460px;
}

#payment-form {
	width: 400px !important;
}

.header-safe-btn {
	margin-right: 15px;
}

.notification-container {
	overflow: hidden;
	width: 320px !important;
	align-items: start !important;
}

.notification-container > div {
	width: 100%;
}

.notification-container::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 7px;
	background-color: #51a14f;
}

.notification {
	width: 100%;
	padding-right: 15px;
}

/* .notification-container::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 5px;
	background-color: #38c976 !important;
} */

.notifications-btn {
	margin-right: 15px;
	height: 38px !important;
	background-color: transparent;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.notifications-btn:hover,
.notifications-btn:active {
	background-color: #eef1ff !important;
}

.notif-dropdown {
	width: 350px;
}

.notifications-btn i {
	font-size: 1.3rem;
}

.back-btn {
	height: 38px !important;
}

.notif-unread {
	height: 7px;
	width: 7px;
	margin-right: 7px;
	border-radius: 50%;
}

.notif-counter {
	position: absolute;
	top: 0;
	left: 20px;
	height: 18px;
	/* width: 27px; */
	padding: 0 7px;
	border-radius: 10px;
	font-size: 0.7rem;
	color: #fff;
}

.notif-dropdown {
	padding: 0;
}

.notif-item {
	padding: 10px;
}

.group-owner-picker {
	height: 40px;
	z-index: 2;
}

.group-content {
	padding-top: 70px !important;
}

.group-owner-item {
	margin-right: 30px;
	height: 100%;
}

.group-owner-item:not(.active) {
	opacity: 0.5;
}

.group-owner-item.active::after {
	content: "";
	position: absolute;
	bottom: -10px;
	left: 0;
	width: 100%;
	height: 3px;
	border-radius: 10px;
	background-color: #fff;
}

.add-group-btn {
	height: 30px;
	min-width: 100px;
}

.safe-msisdn-item:not(:last-of-type) {
	border-bottom: 1px solid rgba(229, 231, 235, 0.5);
}

.page-filter-select {
	width: fit-content !important;
	height: fit-content !important;
	padding: 0 32px 0 0 !important;
	background-color: transparent !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.page-filter-select:not(:last-of-type) {
	margin-right: 15px;
}

.group-settings-btn {
	min-width: 150px;
}

.tab-panel {
	padding-top: 30px;
}

.mfa-code-row {
	min-height: 27px !important;
}

.account-dropdown a {
	min-width: 180px;
}

.dropdown-default a:active {
	background-color: #0d163f !important;
	color: #fff !important;
}

.Snackbar_snackbar-wrapper-bottom-center__21ghq {
	z-index: 9999 !important;
}

.account-usage-item {
	padding: 10px 0;
}

.account-usage-item {
	border-bottom: 1px solid rgba(229, 231, 235, 0.5);
}

.account-usage-item:first-of-type {
	border-top: 1px solid rgba(229, 231, 235, 0.5);
}

.forgot-pw-container {
	margin-top: 10px;
}

.nav-toggle {
	height: 60px;
	cursor: pointer;
	width: 48px;
}

.fries > div {
	transition: 0.3s;
}

.mid-fry {
	width: 18px;
	height: 2px;
	float: right;
	background-color: #3c57cc;
}

.top-fry {
	position: absolute;
	width: 28px;
	height: 2px;
	top: -8px;
	right: 0;
	opacity: 1 !important;
	background-color: #3048b0;
}

.bottom-fry {
	position: absolute;
	width: 24px;
	height: 2px;
	bottom: -8px;
	right: 0;
	background-color: #3048b0;
}

.fries.active .mid-fry {
	opacity: 0;
}

.fries.active .top-fry {
	transform: rotate(40deg);
	top: 0;
}

.fries.active .bottom-fry {
	transform: rotate(-40deg);
	width: 28px;
	top: 0;
}

.nav-sm {
	position: fixed;
	top: -100vh;
	left: 0;
	height: calc(100% - 70px);
	width: 100%;
	transition: 0.4s;
	z-index: 3;
	/* padding-top: 20px; */
}

.nav-sm.login {
	top: -100px;
	height: fit-content;
	border-top: 1px solid rgba(229, 231, 235, 0.5);
	border-bottom: 1px solid rgba(229, 231, 235, 0.5);
}

.nav-sm.active {
	top: 70px;
}

.nav-sm-action {
	position: absolute;
	left: 0;
	bottom: 15px;
}

.group-owner-icon {
	margin-left: 5px;
}

.charge-warning {
	margin-top: 20px;
}

.cancel-invite-btn {
	margin-left: 15px;
}

.group-member-item {
	min-height: 100px;
}

.dashboard-banner {
	background: url("assets/wave-haikei.svg");
	background-size: cover;
	background-position: center;
}

.dashboard-stat {
	height: 120px;
	background-color: #425bcb;
}

.group-banner-btn {
	width: 150px;
}

.pricing-content {
	background-color: #f9fafe;
}

.pricing-tier {
	box-shadow: rgba(0, 44, 155, 0.15) 0px 6px 20px -6px;
	height: 100%;
	padding-bottom: 100px;
}

.tier-btn-container {
	position: absolute;
	left: 0;
	bottom: 0;
	min-height: 88px;
}

.lock-container {
	height: 70vh;
}

.group-item {
	background-color: #fff;
}

.group-item-lock {
	cursor: pointer;
}

.group-item-lock .tooltip {
	visibility: hidden;
	width: 120px;
	background-color: #555;
	color: #fff;
	text-align: center;
	padding: 5px;
	border-radius: 6px;

	/* Position the tooltip text */
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;

	/* Fade in tooltip */
	opacity: 0;
	transition: opacity 0.3s;
}

/* Tooltip arrow */
.group-item-lock .tooltip::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.group-item-lock:hover .tooltip {
	visibility: visible;
	opacity: 1;
}

.switch-modal-body {
	overflow-y: auto;
}

.plan-group-picker:not(:last-of-type) {
	border-bottom: 1px solid rgba(229, 231, 235, 0.5);
}

.plan-group-check {
	height: 20px;
	width: 20px;
	margin-right: 10px;
}

.plan-group-check i {
	display: none;
}

.plan-group-picker:hover {
	background-color: #f8faff;
}

.plan-group-picker.active .plan-group-check {
	background-color: #3048b0 !important;
}

.plan-group-picker.active .plan-group-check i {
	display: inherit;
}

.plan-group-picker.disabled {
	opacity: 0.3;
}

.order {
	overflow: hidden;
}

.order:not(:last-of-type) {
	margin-bottom: 20px;
}

.order.active {
	animation: blink normal 1.5s infinite ease-in-out;
}

.order.Failed {
	border-color: #ff3819 !important;
	background-color: #ffc4bb;
}

.order-loader {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;
	height: 3px;
	background-color: #3048b0;
	width: 0;
	transition: 0.3s;
}

.order-loader.Searching {
	width: 33.33% !important;
}

.order-loader.Purchasing {
	width: 66.66% !important;
}

.order-loader.Activating {
	width: 100% !important;
}

.log-user-icon {
	height: 25px;
	width: 25px;
	border-radius: 50%;
	background: #3c57cc;
	margin-right: 5px;
}

.pointer {
	cursor: pointer;
}

.activity-body {
	display: none;
}

.activity-body.active {
	display: block;
}

.activity-dropdown {
	position: absolute;
	right: 20px;
	transition: 0.3s;
}

.activity-dropdown.active {
	transform: rotate(180deg);
}

.activity-warning {
	position: absolute;
	font-size: 1.5rem;
	right: 50px;
}

.activity-warning-container {
	background-color: rgba(246, 195, 68, 0.5);
	padding: 1px 5px;
}

.access-warning-container {
	background-color: rgba(246, 195, 68, 0.5);
}

.activity-log-content {
	padding-right: 85px;
}

.show-pw-btn {
	position: absolute;
	right: 13px;
}

.content-sm {
	display: none !important;
}

.dashboard-tabs .tab {
	padding: 15px 0;
}

.dashboard-tabs .tab.active {
	color: #131f57;
}

.dashboard-tabs .tab:not(.active) {
	opacity: 0.5;
}

.dashboard-tabs .tab:active {
	background: #dde0ed;
}

.dashboard-tabs::after {
	content: "";
	position: absolute;
	bottom: 0;
	height: 3px;
	width: 50%;
	border-radius: 10px;
	background-color: #131f57;
	transition: 0.3s;
}

.dashboard-tabs.assets::after {
	left: 0;
}

.dashboard-tabs.inbox::after {
	left: 50%;
}

.search-text {
	height: 48px !important;
}

.floating-add-btn {
	position: fixed;
	bottom: 40px;
	right: 20px;
	height: 60px;
	width: 60px;
	border-radius: 50%;
	box-shadow: 2px 2px 3px #999 !important;
}

.floating-add-btn:active {
	box-shadow: none !important;
}

.input-lg {
	height: 48px !important;
}

.form-select__control {
	height: 48px !important;
}

.form-select-input.border-danger .form-select__control {
	border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.unread-icon {
	position: absolute;
	top: 10px;
	left: 10px;
	height: 7px;
	width: 7px;
	border-radius: 50%;
	background: #ff3819;
}

.message-placeholder {
	background-color: #eee;
	height: 100px;
}

.placeholder-content {
	background-color: #a5a5a5;
}

.asset-logo {
	height: 60px;
	width: 60px;
	border-radius: 8px;
	margin-right: 10px;
	background-size: 80% !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
}

.dashboard-asset-logo {
	height: 35px;
	width: 35px;
	border-radius: 8px;
	margin-right: 10px;
	background-size: 80% !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
}

.asset-pw-actions {
	position: absolute;
	right: 10px;
}

.asset-textarea-actions {
	position: absolute;
	right: 10px;
	top: 10px;
}

.asset-pw-actions > button,
.asset-textarea-actions > button {
	height: 30px !important;
	width: 40px !important;
	margin-left: 7px;
}

.asset-pw-actions > button > i,
.asset-textarea-actions > button > i {
	font-size: 0.875rem;
}

.asset-modal .modal-body {
	max-height: calc(100vh - 300px);
	height: calc(100vh - 300px);
	overflow-y: scroll;
}

.asset-notes {
	height: 100px !important;
}

.asset-password {
	padding-right: 170px;
}

.asset-username,
.asset-address .asset-card-info,
.asset-code {
	padding-right: 70px;
}

.asset-tabs > div {
	width: 50%;
}

.asset-tabs .tab.active {
	color: #3c57cc;
}

.asset-tabs .tab.active > span {
	opacity: 1 !important;
}

.asset-tabs .tab.active::after {
	content: "";
	position: absolute;
	bottom: -10px;
	width: 100%;
	height: 2px;
	background-color: #3c57cc;
}

.asset-member,
.asset-owner {
	padding: 15px 12px;
	cursor: pointer;
}

.asset-member-icon {
	height: 40px;
	width: 40px;
	margin-right: 10px;
	border-radius: 50%;
}

.asset-member:not(:last-of-type) {
	border-bottom: 1px solid rgba(229, 231, 235, 0.5);
}

.asset-member-btn {
	min-width: 75px;
}

.member-details-container {
	width: calc(100% - 50px);
}

.asset-modal input:disabled,
.asset-modal select:disabled,
.asset-modal textarea:disabled {
	background-color: #fff !important;
}

.asset-live-code {
	height: 48px;
	padding: 0 0.75rem;
}

.mfa-loader {
	margin-right: 0.75rem;
}

.asset-request {
	border: 2px solid #a3b3f7;
}

.asset-request-status {
	padding: 3px 10px;
	background-color: #7c90eb;
}

.asset-access-settings {
	padding-top: 30px;
	margin-top: 40px;
}

.unlocked-container {
	border-radius: 15px;
}

.asset-type-picker {
	display: inline-block;
	width: fit-content;
	border-radius: 25px;
	padding: 5px 17px;
	cursor: pointer;
	margin-right: 10px;
	margin-bottom: 10px;
	transition: 0.3s;
	user-select: none;
}

.asset-type-picker.active {
	background-color: #344bb2;
	color: #fff;
	border-color: #344bb2 !important;
}

.asset-type-picker i {
	margin-right: 5px;
}

.asset-type-picker:not(.active):hover {
	background-color: #ececec;
}

.dashboard-asset {
	padding: 15px 0;
}

.dashboard-asset:not(:last-of-type) {
	border-bottom: 1px solid rgba(229, 231, 235, 0.5);
}

.dashboard-asset:last-of-type {
	padding-bottom: 0 !important;
}

.msisdn-modal .modal-body {
	padding-top: 0 !important;
	max-height: calc(100vh - 400px);
	height: calc(100vh - 400px);
	overflow-y: auto;
}

.deleted-msisdn-country {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: 1px solid #eee;
	margin-right: 10px;
}

.deleted-msisdn-control {
	position: absolute;
	right: 0;
	padding-right: 20px;
}

.deleted-msisdn-control button {
	height: 30px;
	background-color: #f7f8fc;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	color: #3c57cc !important;
	border-radius: 3px;
	margin-left: 8px;
}

.tab-loader {
	height: 300px;
}

.asset-member:last-of-type {
	border-bottom: none !important;
}

.asset-access-icon {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	margin-right: 5px;
}

.asset-access-toggle {
	padding: 2px 5px;
	background-color: transparent;
	border: none;
	margin-bottom: 3px;
}

.asset-access-label {
	padding-left: 5px;
}

.asset-access-container {
	padding: 15px 0;
}

.asset-link {
	padding-right: 130px;
}

.asset-link-form {
	padding: 5px 130px 5px 10px;
}

.asset-link-input-container {
	width: calc(100% - 100px);
}

.asset-link-action {
	position: absolute;
	right: 4px;
}

.asset-link-action > button {
	width: 100px;
}

.asset-link-copy {
	background-color: #e8ecf8;
	border: none !important;
	box-shadow: none !important;
	outline: none !important;
	color: #3c57cc !important;
	border-radius: 4px;
}

.asset-link-copy:disabled {
	background-color: #334fcb !important;
	color: #fff !important;
}

.asset-link-copy:hover {
	background-color: #d7def6;
}

.asset-link-copy:active {
	background-color: #334fcb !important;
	color: #fff !important;
}

.asset-link-name {
	width: calc(100% - 150px);
}

.asset-access-loader {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 10;
	background-color: rgba(255, 255, 255, 0.7);
}

.asset-access-select {
	width: fit-content;
	margin-left: 10px;
}

.onboarding-container {
	/* border-radius: 14px; */
	overflow: hidden;
}

.onboarding-step-header button {
	background-color: #fff !important;
	box-shadow: none !important;
}

.onboarding-header-icon {
	height: 35px;
	width: 35px;
	border-radius: 50%;
	margin-right: 10px;
	background-color: #e2e7fe !important;
}

.onboarding-item:first-of-type,
.onboarding-item:first-of-type > h2 > button {
	border-radius: 14px 14px 0 0 !important;
}

.onboarding-item:last-of-type,
.onboarding-item:last-of-type > h2 > button {
	border-radius: 0 0 14px 14px !important;
}

.onboarding-item-body {
	padding-bottom: 30px;
}

.tag-item {
	padding: 5px 35px 5px 10px !important;
	margin-right: 10px;
	display: inline-block;
	margin-bottom: 10px !important;
}

.tag-remove-btn {
	position: absolute;
	right: 2px;
	top: 0;
	height: 100%;
	background-color: transparent;
	border: none !important;
	font-size: 1.2rem;
	padding-bottom: 0 !important;
	padding-top: 2px !important;
}

.info-tag {
	padding: 2px 10px;
	margin-right: 10px;
	display: inline-block;
	margin-bottom: 10px;
}

.ReactTags__selected {
	margin-top: 10px;
	margin-bottom: 20px;
}

.onboarding-wizard {
	padding-bottom: 50px;
}

.onboading-logo {
	height: 30px;
	width: fit-content;
}

.asset-link-status {
	padding: 3px 10px;
	border-radius: 3px;
	margin-right: 20px;
	width: 100px !important;
}

.asset-link-status.Active {
	background-color: #51a14f;
}

.asset-link-status.Opened {
	background-color: #e8c762;
}

.asset-link-status.Expired {
	background-color: #999;
}

.wifi-qr {
	height: 100px;
}

.asset-footer {
	padding: 0 20px;
}

.asset-footer > div {
	padding: 10px 0;
}

.asset-type {
	margin-right: 10px;
}

.ext-btn {
	margin-left: 10px;
}

.ext-btn-icon {
	height: 20px;
	margin-right: 10px;
}

/* OTHER SCREENS */

@media screen and (min-width: 993px) {
	.page-sub-content {
		padding-left: 50px;
	}
}

@media screen and (max-width: 992px) {
	.page-sub-content {
		margin-top: 30px;
	}

	.settings-action {
		margin-top: 20px;
		-webkit-justify-content: left;
		justify-content: left;
	}

	.group-tab-header {
		display: block;
		height: fit-content;
		margin-bottom: 20px;
	}

	.group-tab-search {
		height: 48px;
		/* margin-bottom: 30px; */
	}

	.verify-instructions {
		display: block;
		height: fit-content;
		padding: 10px;
	}

	.verify-email-container {
		position: relative;
		-webkit-justify-content: left;
		justify-content: left;
		margin-top: 10px;
		left: 0;
	}

	.member-action {
		margin-top: 20px;
		-webkit-justify-content: left;
		justify-content: left;
	}

	.content-lg {
		display: none;
	}

	.content-sm {
		display: inherit !important;
	}

	.dashboard-banner {
		margin-bottom: 0 !important;
	}

	.plan-switcher-btns {
		margin-top: 20px;
		-webkit-justify-content: left;
		justify-content: left;
	}

	.notification-item-control {
		margin-top: 20px;
		-webkit-justify-content: left;
		justify-content: left;
	}

	.notification-item-control button {
		margin-left: 0 !important;
	}

	.asset-access-select {
		width: fit-content;
		margin-left: 0;
		margin-right: 10px;
	}
}

@media screen and (max-width: 768px) {
	.login-display-container {
		display: none;
	}

	.login-form-header {
		text-align: center;
	}

	.login-form-title,
	.verify-title {
		font-size: 1.75rem !important;
	}

	.login-header {
		padding: 0;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
	}

	.nav-action-container {
		height: 60px;
	}

	.group-cta {
		padding: 15px !important;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
	}

	.group-cta-btn-container {
		margin-top: 20px;
		-webkit-justify-content: left;
		justify-content: left;
	}

	.main-nav,
	.login-header {
		height: 70px;
	}

	.nav-logo {
		width: 110px;
	}

	.dashboard-stat {
		height: fit-content;
		padding: 14% 15px;
		border-radius: 10px !important;
	}

	.dashboard-stat .title-md {
		font-size: 1.5rem;
	}

	.dashboard-stat .text-sm {
		font-size: 0.8rem !important;
	}

	.stat-text-container {
		width: 100%;
		text-align: left;
	}

	.empty-bee {
		height: 80px;
	}

	.empty-label {
		margin-top: 20px;
		font-size: 0.875rem !important;
	}

	.content-item,
	.activity-item,
	.activity-body,
	.settings-group > div {
		padding: 15px;
	}

	.dashboard-item {
		padding: 15px 15px 17px 15px;
	}

	.notification-container {
		max-width: 100% !important;
		width: 100% !important;
	}

	.unlocked-container {
		padding: 20px !important;
	}

	.asset-link {
		padding-right: 120px;
	}
}

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes blink {
	0% {
		background-color: rgba(229, 231, 235, 0);
	}
	50% {
		background-color: rgba(229, 231, 235, 0.8);
	}
	100% {
		background-color: rgba(229, 231, 235, 0);
	}
}
